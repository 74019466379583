import {
  GetCampaignByIdResponse,
  UpdateRequest,
} from '@msl/campaign-gateway-sdk';

export const isInBraces = (
  v: string,
  open: string = '{{',
  close: string = '}}'
) => {
  return v.startsWith(open) && v.endsWith(close);
};

export const stripBraces = (
  v: string,
  open: string = '{{',
  close: string = '}}'
) => {
  let stripped = v;
  if (stripped.startsWith(open)) {
    stripped = stripped.substring(open.length);
  }

  if (stripped.endsWith(close)) {
    stripped = stripped.substring(0, stripped.length - close.length);
  }
  return stripped;
};

export const ensureWrappedWithBraces = (
  v: string,
  open: string = '{{',
  close: string = '}}'
) => {
  return `${open}${stripBraces(v, open, close)}${close}`;
};

export const takePercentNumberValue = (v: string) => {
  const percent = v.endsWith('%');
  const number = percent ? v.slice(0, -1) : v;

  return Number(number);
};

export const mapPropertyToSelectOptionMacros = (
  prefix: 'company' | 'employee',
  f: { property: string }
) => ({
  label: `${prefix.charAt(0).toUpperCase() + prefix.slice(1)} ${f.property}`,
  value: `${prefix}.${f.property}`,
});

export const deepClone = <T>(obj: T): T => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item)) as unknown as T;
  }

  const cloned = {} as T;
  Object.keys(obj as object).forEach((key) => {
    cloned[key as keyof T] = deepClone((obj as object)[key as keyof object]);
  });

  return cloned;
};

export const areObjectsEqual = (obj1: unknown, obj2: unknown): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) =>
    areObjectsEqual(
      (obj1 as Record<string, unknown>)[key],
      (obj2 as Record<string, unknown>)[key]
    )
  );
};

export const hasUnsavedChanges = (
  initialCampaignState: GetCampaignByIdResponse & UpdateRequest,
  campaign: GetCampaignByIdResponse & UpdateRequest
): boolean => {
  const relevantFields = [
    'title',
    'banner',
    'startAt',
    'endAt',
    'groupIds',
    'isPaused',
    'keepBanners',
    'position',
    'priority',
    'utmCampaign',
  ] as const;

  return relevantFields.some(
    (field) => !areObjectsEqual(initialCampaignState[field], campaign[field])
  );
};
