<script setup lang="ts">
import { computed, toValue } from 'vue';
import {
  WsSelect,
  WsSelectHeader,
  WsSelectOption,
  WsToggle,
  WsInput,
} from '@mfl/common-components';

import strings from '../../campaigns.strings';
import { ensureWrappedWithBraces, isInBraces, stripBraces } from '../../utils';
import { AnalyticsModel } from '../../types';

const props = defineProps<{
  name: string | keyof AnalyticsModel;
  placeholder: string;
  label: string;
  tooltip?: string;
  options: Array<{
    header: string;
    options: Array<{
      value: string;
      label: string;
    }>;
  }>;
  toggleAid: string;
  inputAid: string;
  selectAid: string;
}>();

const model = defineModel<string>({
  required: true,
});

const localValue = computed<{
  value: string;
  useVariable: boolean;
}>({
  get() {
    const useVariable = isInBraces(model.value || '');
    return {
      value: stripBraces(model.value || ''),
      useVariable,
    };
  },
  set(v) {
    model.value = v.useVariable
      ? ensureWrappedWithBraces(v.value)
      : stripBraces(v.value);
  },
});

let beforeToggleValue = '';

const localOptions = computed(() =>
  props.options.map(({ header, options }) => {
    return {
      header,
      options: options.map((o) => o.value),
    };
  })
);

const localLabels = computed(() =>
  props.options.reduce(
    (acc, { options }) => {
      return options.reduce((acc, o) => {
        acc[o.value] = o.label;
        return acc;
      }, acc);
    },
    { '{{}}': strings.select, '': strings.select } as Record<string, string>
  )
);

const handleUseVariableToggle = (v: boolean) => {
  const oldValue = toValue(localValue).value;
  if (v) {
    localValue.value = {
      useVariable: true,
      value: beforeToggleValue,
    };
  } else {
    localValue.value = {
      value: beforeToggleValue,
      useVariable: v,
    };
  }
  beforeToggleValue = oldValue;
};

const handleSelectChange = (v: unknown) => {
  localValue.value = {
    ...localValue.value,
    value: v as string,
  };
};
</script>
<template>
  <div class="input-select-container">
    <div class="input-select-label-toggle-container">
      <div class="input-select-label-container">
        <label class="input-select-label">{{ props.label }}</label>
      </div>
      <div class="input-select-toggle-container">
        <p class="use-variable-label">
          {{ strings.useVariable }}
        </p>
        <ws-toggle
          v-model:model-value="localValue.useVariable"
          :aid="props.toggleAid"
          @update:model-value="handleUseVariableToggle"
        />
      </div>
    </div>
    <div class="input-select-control-container">
      <div
        v-if="localValue.useVariable"
        class="input-select-control-container-select-container"
      >
        <ws-select
          :model-value="localValue.value"
          :option-label="(v) => localLabels[v]"
          :option-key="(v) => v"
          :aid="props.selectAid"
          @update:model-value="handleSelectChange"
        >
          <template v-for="group in localOptions" :key="group.header">
            <template v-if="group.options?.length > 0">
              <WsSelectHeader>{{ group.header }}</WsSelectHeader>
              <WsSelectOption
                v-for="opt in group.options"
                :key="opt"
                :value="opt"
              >
                {{ localLabels[opt] }}
              </WsSelectOption>
            </template>
          </template>
        </ws-select>
      </div>
      <ws-input
        v-else
        v-model="model"
        :aid="props.inputAid"
        size="md"
        :placeholder="props.placeholder"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.input-select-container {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .input-select-label-toggle-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .input-select-label-container {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: flex-start;

      .input-select-label {
        font-size: 13px;
        color: #828282;
      }
    }

    .input-select-toggle-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 10px;

      .use-variable-label {
        font-size: 13px;
        color: #828282;
      }
    }
  }

  .input-select-control-container-select-container {
    padding-bottom: 20px;
  }
}
</style>
