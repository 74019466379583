<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import {
  CampaignForPreview,
  campaignGateway,
  Employee,
  Group,
} from '@msl/campaign-gateway-sdk';
import {
  WsHtmlViewer,
  WsSelect,
  WsSelectPanel,
  WsSelectSearchInput,
  WsSelectOptions,
  WsSelectOption,
  WsSelectHeader,
  WsSelectTrigger,
  vLoading,
} from '@mfl/common-components';

import strings from '../../campaigns.strings';
import magnifyingGlassIcon from '../../assets/magnifying-plus.svg';
import { DeepRequired } from '../../types';

const props = defineProps<{
  groups: DeepRequired<Group>[];
  employees: DeepRequired<Employee>[];
  campaignData: CampaignForPreview;
}>();

const scale = ref<'actual' | 'fit'>('actual');
const content = ref<string>('');

const scaleToFit = computed(() => (scale.value === 'fit' ? 'fit' : 1));

const searchText = ref('');
const selectedId = ref<string>('');
const selectedItemData = ref<{
  groupId?: string;
  employeeId?: string;
}>({ groupId: '', employeeId: '' });
const isLoading = ref(false);

// Filter out placeholder groups as they are not used in the signature preview
const nonPlaceholderGroups = computed(() => {
  return props.groups.filter((group) => !group.isPlaceholder);
});

watch(
  () => props.groups,
  () => {
    if (nonPlaceholderGroups.value.length) {
      const group = nonPlaceholderGroups.value[0];
      selectedId.value = group.groupId;
      selectedItemData.value = {
        groupId: group.groupId,
        employeeId: '',
      };
    }
  }
);

watch(
  () => props.employees,
  () => {
    if (props.employees.length && !selectedId.value) {
      const employee = props.employees[0];
      selectedId.value = employee.employeeId;
      selectedItemData.value = {
        employeeId: employee.employeeId,
        groupId: '',
      };
    }
  }
);

// Filter groups based on search
const filteredGroups = computed(() => {
  const search = searchText.value.toLowerCase();
  return nonPlaceholderGroups.value.filter((group) =>
    group.name.toLowerCase().includes(search)
  );
});

// Filter employees based on search but also limit the number of employees to display to 30 due to performance issues
const filteredEmployees = computed(() => {
  const search = searchText.value.toLowerCase();
  return props.employees
    .filter((employee) => employee.name.toLowerCase().includes(search))
    .slice(0, 30);
});

function getItemLabel(id: string): string {
  const group = nonPlaceholderGroups.value.find((g) => g.groupId === id);
  if (group) return group.name;

  const employee = props.employees.find((e) => e.employeeId === id);
  if (employee) return `${employee.name}`;

  return '';
}

let debounceTimeout: NodeJS.Timeout;
function getSignatureHtmlDebounced() {
  clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(async () => {
    const { banner, utmCampaign, position, keepBanners } = props.campaignData;
    isLoading.value = true;
    const { html } = await campaignGateway.getSignatureHtml({
      campaign: {
        banner,
        utmCampaign,
        position,
        keepBanners,
      },
      employeeId: selectedItemData.value.employeeId,
      groupId: selectedItemData.value.groupId,
    });

    content.value = html || '';
    isLoading.value = false;
  }, 200);
}

watch(
  [() => props.campaignData, selectedItemData],
  async () => {
    getSignatureHtmlDebounced();
  },
  { deep: true }
);

onMounted(async () => {
  try {
    getSignatureHtmlDebounced();
  } catch {
    console.error('An error occured while fetching a signature');
  }
});

onUnmounted(() => {
  clearTimeout(debounceTimeout);
});

function handleSelectChange(item: unknown) {
  selectedId.value = (item as string) || '';

  const selectedGroup = nonPlaceholderGroups.value.find(
    (g) => g.groupId === item
  );
  const selectedEmployee = props.employees.find((e) => e.employeeId === item);
  selectedItemData.value = {
    groupId: selectedGroup?.groupId || '',
    employeeId: selectedEmployee?.employeeId || '',
  };
}
</script>

<template>
  <div class="campaign-editor_signature-preview-container">
    <div class="campaign-editor_signature-preview">
      <div class="campaign-editor_signature-preview-label">
        {{ strings.newMessage }}
      </div>
      <div class="campaign-editor_signature-preview-wrap">
        <div class="campaign-editor_signature-preview-details">
          <div class="campaign-editor_signature-preview-details-to">To</div>
          <div class="campaign-editor_signature-preview-details-subject">
            {{ strings.subject }}
          </div>
        </div>
        <div
          v-loading="isLoading"
          class="campaign-editor_signature-preview-body"
        >
          <WsHtmlViewer
            :scale="scaleToFit"
            :content="content"
            aid="CAMPAIGN_SIGNATURE_PREVIEW"
          />
        </div>
      </div>
    </div>

    <div class="campaign-editor_signature-preview-footer">
      <div class="campaign-editor_signature-preview-footer-left">
        {{ strings.previewWith }}
        <div class="campaign-editor_signature-preview-select-wrapper">
          <WsSelect
            v-model="selectedId"
            :option-key="(val: string) => val"
            :option-label="getItemLabel"
            size="sm"
            aid="SIGNATURE_PREVIEW_WITH_SELECT"
            advanced
            @update:model-value="handleSelectChange"
          >
            <WsSelectTrigger />

            <WsSelectPanel>
              <WsSelectSearchInput v-model="searchText" />

              <WsSelectOptions>
                <!-- Groups section -->
                <WsSelectHeader v-if="filteredGroups.length">{{
                  strings.groups
                }}</WsSelectHeader>
                <WsSelectOption
                  v-for="group in filteredGroups"
                  :key="group.groupId"
                  :value="group.groupId"
                  :aid="`SIGNATURE_PREVIEW_WITH_SELECT_GROUP_${group.groupId}`"
                >
                  {{ group.name }}
                </WsSelectOption>

                <!-- Employees section -->
                <WsSelectHeader v-if="filteredEmployees.length">{{
                  strings.employees
                }}</WsSelectHeader>
                <WsSelectOption
                  v-for="employee in filteredEmployees"
                  :key="employee.employeeId"
                  :value="employee.employeeId"
                  :aid="`SIGNATURE_PREVIEW_WITH_SELECT_EMPLOYEE_${employee.employeeId}`"
                >
                  {{ employee.name }}
                </WsSelectOption>

                <!-- Show when no results found -->
                <WsSelectOption
                  v-if="
                    filteredGroups.length === 0 &&
                    filteredEmployees.length === 0
                  "
                  disabled
                >
                  {{ strings.noResults }}
                </WsSelectOption>
              </WsSelectOptions>
            </WsSelectPanel>
          </WsSelect>
        </div>
      </div>
      <div class="campaign-editor_signature-preview-footer-right">
        <WsSelect
          v-model="scale"
          :option-key="(val: string) => val"
          :option-label="
            (val: 'actual' | 'fit') =>
              val === 'actual' ? strings.actualSize : strings.fitToScale
          "
          aid="CAMPAIGN_SIGNATURE_PREVIEW_SCALE_SELECT"
          size="sm"
          advanced
        >
          <WsSelectTrigger>
            <div class="select-trigger">
              <div class="select-trigger-content">
                <img :src="magnifyingGlassIcon" class="select-icon" />
                <span class="select-text">{{
                  scale === 'actual' ? strings.actualSize : strings.fitToScale
                }}</span>
              </div>
              <i
                class="select-arrow fa-regular fa-chevron-down"
                aria-hidden="true"
              />
            </div>
          </WsSelectTrigger>

          <WsSelectPanel>
            <WsSelectOptions>
              <WsSelectOption
                value="actual"
                aid="CAMPAIGN_SIGNATURE_PREVIEW_SCALE_ACTUAL"
              >
                {{ strings.actualSize }}
              </WsSelectOption>
              <WsSelectOption
                value="fit"
                aid="CAMPAIGN_SIGNATURE_PREVIEW_SCALE_FIT"
              >
                {{ strings.fitToScale }}
              </WsSelectOption>
            </WsSelectOptions>
          </WsSelectPanel>
        </WsSelect>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.campaign-editor_signature-preview-container {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f4f6f8;
  padding-top: 100px;
  height: calc(100vh - 65px);
  width: 100%;
}

.campaign-editor_signature-preview {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 600px;
  padding: 0;
  margin: 0 auto;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  max-height: calc(
    100vh - 307px
  ); /* 307px = 58px of header + 49px of footer + 100px of padding-top + 100px of padding-bottom */

  &-label {
    border-radius: 6px 6px 0 0;
    padding: 10px;
    font-size: 9px;
    font-weight: 700;
    color: rgb(var(--color-gray-400));
    background-color: rgb(var(--color-primary-300));
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 6px 6px;
    padding: 0 20px 20px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    will-change: height;
    background-color: #fff;
  }

  &-details {
    margin-bottom: 20px;
    font-size: 12px;
    color: rgb(var(--color-gray-300));

    &-to,
    &-subject {
      padding: 10px 20px;
      border-bottom: 1px solid rgb(var(--color-gray-200));
    }
  }

  &-body {
    display: flex;
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #9f9f9f;
    }
    &::-webkit-scrollbar-track {
      background-color: #ccc;
    }
  }

  &-footer {
    display: flex;
    height: 49px;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid #edeeef;
    background: #fff;

    &-left {
      display: flex;
      align-items: center;
      gap: 20px;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      color: #2d2e30;
    }

    &-right {
      width: 180px;
    }
  }

  &-select-wrapper {
    align-items: stretch;
    width: 240px;
  }
}

.select-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 6px 12px;
  min-width: 180px;
  height: 36px;
  cursor: default;

  .select-trigger-content {
    display: flex;
    align-items: center;
    gap: 6px;

    .select-icon {
      width: 12px;
      height: 12px;
    }

    .select-text {
      flex-grow: 1;
      font-size: 13px;
      color: #2d2e30;
    }
  }

  .select-arrow {
    font-size: 12px;
    color: #2d2e30;
    transition: transform 0.2s ease;
  }
}
</style>
