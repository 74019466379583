<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { ValueGetterParams } from 'ag-grid-community';

import { navigateTo } from '@mfl/framework';
import { WsButton, WsInput } from '@mfl/common-components';
import { NavigationBarMode, navigationBarMode } from '@mfe/core-navigation';
import { currentUser, trackEvent } from '@mfl/platform-shell';
import { campaignGateway, ThinCampaign } from '@msl/campaign-gateway-sdk';

import emptyStateImg from '../assets/empty-state-cmp.png';
import strings from '../campaigns.strings';
import RowActions from './components/row-actions.vue';
import StatusCell from './components/status-cell.vue';
import NoRows from './components/no-rows.vue';
import WsGrid from './components/ws-grid/ws-grid.vue';

const priorityMap = {
  1: strings.priorityHigh,
  2: strings.priorityMedium,
  3: strings.priorityLow,
};

const columnDefs = [
  {
    field: 'name',
    headerName: strings.tableNameColumn,
    suppressMovable: true,
    flex: 1,
    valueGetter: getName,
  },
  {
    field: 'status',
    headerName: strings.tableStatusColumn,
    suppressMovable: true,
    flex: 1,
    cellRenderer: 'StatusCell',
    valueGetter: calculateCampaignStatus,
  },
  {
    field: 'timeFrame',
    headerName: strings.tableTFColumn,
    suppressMovable: true,
    flex: 1,
    valueGetter: getTimeFrame,
  },
  {
    field: 'assignedTo',
    headerName: strings.tableAssignedColumn,
    suppressMovable: true,
    flex: 1,
    valueGetter: getAssignedTo,
  },
  {
    field: 'views',
    headerName: strings.tableViewsColumn,
    suppressMovable: true,
    flex: 1,
    valueGetter: getViews,
  },
  {
    field: 'priority',
    headerName: strings.tablePriorityColumn,
    suppressMovable: true,
    flex: 1,
    valueGetter: getPriority,
  },
  {
    field: 'lastUpdated',
    headerName: strings.tableUpdatedColumn,
    suppressMovable: true,
    flex: 1,
    valueGetter: getLastUpdated,
  },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: 'RowActions',
    cellRendererParams: {
      onCampaignDeleted: (campaignId: string) => {
        originalCampaignRows.value = originalCampaignRows.value.filter(
          (campaign) => campaign.campaignId !== campaignId
        );
        campaignRows.value = campaignRows.value.filter(
          (campaign) => campaign.campaignId !== campaignId
        );
      },
      onCampaignRenamed: (campaignId: string, newTitle: string) => {
        updateCampaignInLists(campaignId, (campaign) => ({
          ...campaign,
          title: newTitle,
        }));
      },
      onCampaignPauseToggled: (campaignId: string, isPaused: boolean) => {
        updateCampaignInLists(campaignId, (campaign) => ({
          ...campaign,
          isPaused,
        }));
      },
      onCampaignEnded: (campaignId: string) => {
        updateCampaignInLists(campaignId, (campaign) => ({
          ...campaign,
          endAt: Date.now(),
        }));
      },
    },
    suppressMovable: true,
    sortable: false,
    flex: 1,
  },
];

const originalCampaignRows = ref<ThinCampaign[]>([]);
const campaignRows = ref<ThinCampaign[]>([]);
const isLoading = ref<boolean>(true);
const searchInput = ref<string>('');

const gridData = computed(() => ({
  rowData: campaignRows.value,
  columnDefs: columnDefs,
  customComponents: {
    StatusCell,
    RowActions,
    NoRows,
  },
  noRowsOverlayComponent: 'NoRows',
}));

// Debounced search
let debounceTimeout: NodeJS.Timeout;
function debouncedSearch(searchValue: string) {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(() => {
    if (!searchValue.trim()) {
      campaignRows.value = originalCampaignRows.value;
      return;
    }

    const searchTerm = searchValue.toLowerCase().trim();
    campaignRows.value = originalCampaignRows.value.filter((campaign) =>
      campaign.title?.toLowerCase().includes(searchTerm)
    );
  }, 300);
}

// Watch for changes in search input
watch(searchInput, (newValue) => {
  debouncedSearch(newValue);
});

function getName(params: ValueGetterParams<ThinCampaign>): string {
  return params.data?.title || '';
}

function calculateCampaignStatus(
  params: ValueGetterParams<ThinCampaign>
): string {
  const dateNow = new Date();
  const startAt = new Date(params.data?.startAt || '');
  const endAt = new Date(params.data?.endAt || '');

  if (startAt > dateNow) return strings.scheduledStatus;
  if (endAt < dateNow) return strings.endedStatus;
  if (params.data?.isPaused) return strings.pausedStatus;
  if (startAt <= dateNow && endAt > dateNow) return strings.liveStatus;

  return strings.scheduledStatus;
}

function getTimeFrame(params: ValueGetterParams<ThinCampaign>): string {
  if (!params.data?.startAt || !params.data?.endAt) return '';

  const startAt = new Date(params.data?.startAt || '');
  const endAt = new Date(params.data?.endAt || '');
  const startMonth = startAt.toLocaleString('default', { month: 'short' });
  const startDay = startAt.getDate();
  const endMonth = endAt.toLocaleString('default', { month: 'short' });
  const endDay = endAt.getDate();

  return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
}

function getAssignedTo(params: ValueGetterParams<ThinCampaign>): string {
  if (!params.data?.groupIds?.length || !params.data?.groupName)
    return strings.allGroups;

  return `${params.data?.groupName}${params.data.groupIds.length > 1 ? `+${params.data.groupIds.length - 1}` : ''}`;
}

function getViews(params: ValueGetterParams<ThinCampaign>): string {
  const views = params.data?.views || 0;

  if (views === 0) return '0';

  if (views >= 1000000) {
    return `${(views / 1000000).toFixed(1)}M`;
  }

  if (views >= 1000) {
    return `${(views / 1000).toFixed(1)}K`;
  }

  return views.toString();
}

function getPriority(params: ValueGetterParams<ThinCampaign>): string {
  return priorityMap[params.data?.priority || 2];
}

function getLastUpdated(params: ValueGetterParams<ThinCampaign>): string {
  const updatedAt = new Date(params.data?.updatedAt || '');
  const month = updatedAt.toLocaleString('default', { month: 'short' });

  return `${month} ${updatedAt.getDate()}`;
}

const updateCampaignInLists = (
  campaignId: string,
  updater: (campaign: ThinCampaign) => ThinCampaign
) => {
  originalCampaignRows.value = originalCampaignRows.value.map((campaign) =>
    campaign.campaignId === campaignId ? updater(campaign) : campaign
  );
  campaignRows.value = campaignRows.value.map((campaign) =>
    campaign.campaignId === campaignId ? updater(campaign) : campaign
  );
};

function createCampaign(event: MouseEvent) {
  event.preventDefault();

  trackEvent({
    eventName: 'go_to_create_campign',
    eventMetadata: {
      action: 'navigation',
      category: 'Funnel',
      attributes: {
        account_id: currentUser.accountId,
      },
    },
  });

  navigateTo('/v1/campaigns/editor');
}

onMounted(async () => {
  navigationBarMode.value = NavigationBarMode.FullView;

  const { campaigns = [] } = await campaignGateway.listAccountDomainCampaigns(
    {}
  );

  originalCampaignRows.value = campaigns;
  campaignRows.value = campaigns;
  isLoading.value = false;
});

defineExpose({
  RowActions,
  StatusCell,
  NoRows,
});
</script>

<template>
  <div class="campaigns-container">
    <div class="header">
      <span class="text-2xl font-bold">{{ strings.header }}</span>
      <div
        v-if="originalCampaignRows.length && !isLoading"
        class="buttons-group"
      >
        <WsButton
          aid="CHANGE_TZ_BUTTON"
          class="change-timezone-button"
          color="primary"
          variant="text"
          href="/v1/settings/domain"
          target="_blank"
          :label="strings.changeTZ"
        />
        <WsButton
          aid="CREATE_CAMPAIGN_BUTTON"
          class="create-campaign-button"
          color="primary"
          href="/v1/campaigns/editor"
          :label="strings.createCampaignBtn"
          @click="createCampaign"
        />
      </div>
    </div>

    <div
      v-if="!originalCampaignRows.length && !isLoading"
      aid="EMPTY_STATE_CONTAINER"
      class="empty-state-container"
    >
      <img class="empty-state-img" alt="campaigns" :src="emptyStateImg" />
      <div class="empty-state-content">
        <span class="empty-state-header">{{ strings.emptyStateTitle }}</span>
        <span class="empty-state-text">{{ strings.emptyStateText }}</span>
      </div>
      <WsButton
        aid="ADD_CAMPAIGN_BUTTON"
        class="add-campaign-button"
        color="primary"
        size="lg"
        href="/v1/campaigns/editor"
        :label="strings.createCampaignBtn"
        @click="createCampaign"
      />
    </div>

    <div v-if="originalCampaignRows.length && !isLoading" class="content">
      <WsInput
        v-model="searchInput"
        class="search-input"
        aid="CAMPAIGNS_SEARCH_INPUT"
        :placeholder="strings.searchPlaceholder"
        size="sm"
      >
        <template #prepend>
          <span aria-hidden="true" class="fa-regular fa-search text-[1rem]" />
        </template>
      </WsInput>
      <div class="grid-wrapper">
        <WsGrid v-bind="gridData" aid="CAMPAIGNS_LIST_GRID" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.campaigns-container {
  display: flex;
  padding: 24px;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    .buttons-group {
      display: flex;
      gap: 10px;

      .change-timezone-button {
        font-size: 13px;
      }

      .create-campaign-button {
        font-size: 13px;
        width: 145px;
        height: 31px;
        padding: 8px 20px;
      }
    }
  }

  .empty-state-container {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 30px;
    padding: 100px 0;

    .empty-state-img {
      width: 370px;
      height: 275px;
    }

    .empty-state-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      .empty-state-header {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
      }

      .empty-state-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }

  .content {
    .search-input {
      padding-bottom: 14px;
    }
  }
}
</style>
