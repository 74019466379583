import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { CAMPAIGNS_MANAGE_PERMISSION } from '@msl/campaign-gateway-sdk';
import { currentUser } from '@mfl/platform-shell';

import { baseRoute } from './constants';
import CampaignEditor from './campaign-editor/campaign-editor.vue';
import CampaignList from './campaigns-list/campaign-list.vue';

const routes: RouteRecordRaw[] = [
  {
    name: 'campaigns',
    path: baseRoute,
    component: CampaignList,
  },
  {
    name: 'campaign-editor',
    path: `${baseRoute}/editor/:campaignId?`,
    component: CampaignEditor,
  },
];

const router = createRouter({
  history: createWebHistory(''),
  routes,
});

router.beforeEach((to) => {
  if (
    to.name === 'campaign-editor' &&
    !currentUser.isAllowedTo(CAMPAIGNS_MANAGE_PERMISSION)
  ) {
    return { name: 'campaigns' };
  }
});

export { router };
