<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  WsButton,
  IDialog,
  WsDialogActions,
  WsDialogSection,
  WsDialogForm,
} from '@mfl/common-components';
import {
  ThinCampaign,
  DeleteResponse,
  campaignGateway,
} from '@msl/campaign-gateway-sdk';

import strings from '../../../campaigns.strings';

const { dialog } = defineProps<{
  dialog: IDialog<ThinCampaign & { status: string }, DeleteResponse>;
}>();

const busy = ref(false);
const campaign = ref<ThinCampaign>({});

const modalText = computed(() => {
  if (dialog.input?.status === strings.liveStatus) {
    return `${strings.deleteLiveCampaignModalMessage} ‘${dialog.input.title}’?`;
  }
  return `${strings.deleteCampaignModalMessage} ‘${dialog.input.title}’?`;
});

if (dialog.input) {
  campaign.value = dialog.input;
}

async function deleteCampaign() {
  busy.value = true;

  try {
    const result = await campaignGateway.delete({
      campaignId: campaign.value.campaignId || '',
    });
    dialog.close(result);
  } catch (error) {
    console.error('Delete campaign error', error);
  } finally {
    busy.value = false;
  }
}

function closeMe() {
  dialog.close();
}
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="campaign-delete-confirmation-section">
      {{ modalText }}
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        aid="DELETE_CAMPAIGN_CANCEL"
        variant="outlined"
        color="gray-500"
        @click="closeMe"
      >
        {{ strings.cancel }}
      </WsButton>
      <WsButton
        aid="DELETE_CAMPAIGN_CONFIRM"
        variant="contained"
        color="danger"
        :loading="busy"
        @click="deleteCampaign"
      >
        {{ strings.delete }}
      </WsButton>
    </WsDialogActions>
  </WsDialogForm>
</template>

<style lang="scss">
.campaign-delete-confirmation-section {
  width: 400px;
  padding: 0 24px 14px 24px !important;
}
</style>
