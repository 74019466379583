<script setup lang="ts">
import { computed, ref } from 'vue';

import {
  WsInputDatePicker,
  WsSelect,
  WsSelectOption,
  WsSlider,
  WsToggle,
  WsSelectPanel,
  WsSelectSearchInput,
  WsSelectTrigger,
  WsSelectOptions,
} from '@mfl/common-components';
import {
  Category,
  Group,
  Macros,
  Position,
  Priority,
} from '@msl/campaign-gateway-sdk';

import strings from '../../campaigns.strings';
import InputSelect from './input-select.vue';
import { CampaignEditorModel, DeepRequired } from '../../types';
import {
  mapPropertyToSelectOptionMacros,
  takePercentNumberValue,
} from '../../utils';
import AlignmentRadioGroup from './alignment-radio-group.vue';
import BannerUploader from './banner-uploader.vue';
import CampaignAnalytics from './campaign-analytics.vue';

const DEFAULT_BANNER = {
  imageUrl: '',
  linkUrl: '',
  bannerSize: '75%',
  alignment: 'left',
} satisfies Required<NonNullable<CampaignEditorModel['banner']>>;

const props = defineProps<{
  modelValue: CampaignEditorModel;
  groups: DeepRequired<Group>[];
  macros: DeepRequired<Macros>;
  bannerApps: DeepRequired<Category>[];
}>();

const positionOptions = ref<{ label: string; value: Position; aid: string }[]>([
  {
    label: strings.aboveDetails,
    value: Position.ABOVE,
    aid: 'CAMPAIGN_EDITOR_POSITION_ABOVE',
  },
  {
    label: strings.belowDetails,
    value: Position.BELOW_DETAILS_LEGACY,
    aid: 'CAMPAIGN_EDITOR_POSITION_BELOW_DETAILS',
  },
  {
    label: strings.footer,
    value: Position.BELOW,
    aid: 'CAMPAIGN_EDITOR_POSITION_BELOW',
  },
]);

const priorityOptions = ref<{ label: string; value: Priority; aid: string }[]>([
  {
    label: strings.priorityLow,
    value: Priority.LOW,
    aid: 'CAMPAIGN_EDITOR_PRIORITY_LOW',
  },
  {
    label: strings.priorityMedium,
    value: Priority.MEDIUM,
    aid: 'CAMPAIGN_EDITOR_PRIORITY_MEDIUM',
  },
  {
    label: strings.priorityHigh,
    value: Priority.HIGH,
    aid: 'CAMPAIGN_EDITOR_PRIORITY_HIGH',
  },
]);

const getFormattedYearMonth = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${year}/${month}`;
};

const minYearMonthStartDate = computed(() => {
  return getFormattedYearMonth(new Date());
});

const minYearMonthEndDate = computed(() => {
  return getFormattedYearMonth(new Date(model.value.startAt || ''));
});

const ALL_GROUPS_ID = 'all';
const selectedGroupIds = computed({
  get() {
    // If no specific groups are selected, show 'all' as selected
    if (!model.value.groupIds?.length) {
      return [ALL_GROUPS_ID];
    }
    // Otherwise show the selected groups filtered by only existing groups (not deleted)
    return model.value.groupIds.filter((id) =>
      props.groups.find((g) => g.groupId === id)
    );
  },
  set(value) {
    // Handle empty selection - default to 'all'
    if (!value?.length) {
      model.value.groupIds = [];
      return;
    }

    // Handle 'all' selection
    const hadAll = value.includes(ALL_GROUPS_ID);
    const previouslyHadAll = selectedGroupIds.value.includes(ALL_GROUPS_ID);

    if (hadAll && !previouslyHadAll) {
      // 'all' was just selected - clear other selections
      model.value.groupIds = [];
    } else if (!hadAll && previouslyHadAll) {
      // 'all' was just deselected - use the new selection
      model.value.groupIds = value;
    } else {
      // Normal group selection/deselection
      model.value.groupIds = value.filter((id) => id !== ALL_GROUPS_ID);
    }
  },
});
const groupSearch = ref<string>('');

const ALL_GROUPS_OPTION = { groupId: ALL_GROUPS_ID, name: strings.allGroups };
const groups = computed(() => [ALL_GROUPS_OPTION, ...props.groups]);

const filteredGroupsList = computed(() => {
  const search = groupSearch.value.toLowerCase();

  if (search.length < 3) {
    return groups.value;
  }

  return groups.value.filter((group) =>
    group.name.toLowerCase().includes(search)
  );
});

const macroFields = computed(() => {
  return [
    {
      header: strings.analyticsGeneralCompanyFieldSection,
      options: props.macros.company
        .filter((m) => m.fieldType === 'text')
        .map((m) => mapPropertyToSelectOptionMacros('company', m)),
    },
    {
      header: strings.analyticsGeneralEmployeeFieldSection,
      options: props.macros.employee
        .filter((m) => m.fieldType === 'text')
        .map((m) => mapPropertyToSelectOptionMacros('employee', m)),
    },
  ];
});

const campaignLinkField = {
  label: strings.campaignLink,
  name: 'campaignLink',
  placeholder: '',
  options: macroFields,
  toggleLabel: strings.useVariable,
  toggleAid: 'CAMPAIGN_EDITOR_LINK_USE_VARIABLE',
  inputAid: 'CAMPAIGN_EDITOR_LINK_INPUT',
  selectAid: 'CAMPAIGN_EDITOR_LINK_SELECT',
};

const model = defineModel<CampaignEditorModel>({
  required: true,
});

const startDate = computed<string>({
  get() {
    const date = new Date(model.value.startAt || '');
    return date
      .toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', '');
  },
  set(v) {
    model.value.startAt = new Date(v).getTime();
  },
});
const endDate = computed<string>({
  get() {
    const date = new Date(model.value.endAt || '');
    return date
      .toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', '');
  },
  set(v) {
    model.value.endAt = new Date(v).getTime();
  },
});
const removeOtherBanners = computed({
  get() {
    return !model.value.keepBanners;
  },
  set(v) {
    model.value.keepBanners = !v;
  },
});

const sizeValue = computed<number>({
  get() {
    return takePercentNumberValue(model.value.banner?.bannerSize || '75%');
  },
  set(v) {
    model.value.banner!.bannerSize = `${v}%`;
  },
});

const positionLabels: Record<Position, string> = positionOptions.value.reduce(
  (acc, o) => {
    acc[o.value] = o.label;
    return acc;
  },
  {} as Record<Position, string>
);

function getGroupLabel(id: string) {
  return groups.value.find((g) => g.groupId === id)?.name || '';
}

function handleUpdatePosition(value: unknown) {
  model.value.position = value as Position;
}

const priorityLabels: Record<Priority, string> = priorityOptions.value.reduce(
  (acc, o) => {
    acc[o.value] = o.label;
    return acc;
  },
  {} as Record<Priority, string>
);

function handleUpdatePriority(value: unknown) {
  model.value.priority = value as Priority;
}

function handleCampaignLinkChange(value: string) {
  model.value.banner!.linkUrl = value;
}

const banner = computed({
  get: () => ({
    ...DEFAULT_BANNER,
    ...model.value.banner,
  }),
  set: (value) => (model.value.banner = value),
});
</script>

<template>
  <div class="toolbar-container">
    <BannerUploader v-model="banner" :banner-apps="bannerApps" />

    <div class="toolbar-container_select-position horizontal-label-control">
      <div class="grey-text">{{ strings.bannerPosition }}</div>
      <div class="horizontal-control">
        <WsSelect
          size="xs"
          :option-key="(val: Position) => val"
          :option-label="(v: Position) => positionLabels[v]"
          :model-value="model.position"
          aid="CAMPAIGN_EDITOR_BANNER_POSITION"
          @update:model-value="handleUpdatePosition"
        >
          <WsSelectOption
            v-for="opt in positionOptions"
            :key="opt.value"
            :aid="opt.aid"
            :value="opt.value"
          >
            {{ opt.label }}
          </WsSelectOption>
        </WsSelect>
      </div>
    </div>

    <div
      class="toolbar-container_horizontal-alignment horizontal-label-control"
    >
      <div class="grey-text">{{ strings.horizontalAlignment }}</div>
      <div class="horizontal-control">
        <AlignmentRadioGroup
          v-model="model.banner!.alignment as 'left' | 'center' | 'right'"
        />
      </div>
    </div>

    <div class="toolbar-container_size horizontal-label-control">
      <div class="grey-text">{{ strings.size }}</div>
      <div class="horizontal-control">
        <WsSlider
          v-model.number="sizeValue"
          :label="false"
          :min="1"
          :max="100"
          :step="1"
          suffix="%"
          type="edit"
          aid="CAMPAIGN_EDITOR_SIZE"
        />
      </div>
    </div>

    <div class="horizontal-line" />

    <div class="toolbar-container_remove-banners horizontal-label-control">
      <div class="grey-text">
        {{ strings.removeOtherBannersDuringCampaign }}
      </div>
      <WsToggle
        v-model="removeOtherBanners"
        aid="CAMPAIGN_EDITOR_REMOVE_OTHER_BANNERS"
      />
    </div>

    <div class="toolbar-container_time-frame vertical-label-control">
      <div class="grey-text">{{ strings.activationTimeFrame }}</div>
      <div class="toolbar-container_time-frame_date-pickers">
        <WsInputDatePicker
          v-model="startDate"
          :placeholder="strings.from"
          :min-year-month="minYearMonthStartDate"
          aid="CAMPAIGN_EDITOR_START_DATE"
          size="md"
        />
        <WsInputDatePicker
          v-model="endDate"
          :placeholder="strings.to"
          :min-year-month="minYearMonthEndDate"
          aid="CAMPAIGN_EDITOR_END_DATE"
          size="md"
        />
      </div>
    </div>

    <div class="toolbar-container_assign-campaing-to vertical-label-control">
      <div class="grey-text">{{ strings.assignCampaignTo }}</div>
      <WsSelect
        v-model="selectedGroupIds"
        :option-key="(val: string) => val"
        :option-label="getGroupLabel"
        aid="CAMPAIGN_EDITOR_ASSIGN_GROUP"
        multiple
        advanced
        checkboxes
        chips
      >
        <WsSelectTrigger />

        <WsSelectPanel>
          <WsSelectSearchInput v-model="groupSearch" />

          <WsSelectOptions>
            <WsSelectOption
              v-for="group in filteredGroupsList"
              :key="group.groupId"
              :value="group.groupId"
              :aid="`CAMPAIGN_EDITOR_GROUP_${group.groupId}`"
            >
              {{ group.name }}
            </WsSelectOption>

            <WsSelectOption v-if="filteredGroupsList.length === 0" disabled>
            </WsSelectOption>
          </WsSelectOptions>
        </WsSelectPanel>
      </WsSelect>
    </div>

    <div class="toolbar-container_campaign-priority vertical-label-control">
      <div class="grey-text">{{ strings.campaignPriority }}</div>
      <WsSelect
        size="md"
        :option-key="(val: Priority) => val"
        :option-label="(v: Priority) => priorityLabels[v]"
        :model-value="model.priority"
        aid="CAMPAIGN_EDITOR_PRIORITY"
        @update:model-value="handleUpdatePriority"
      >
        <WsSelectOption
          v-for="opt in priorityOptions"
          :key="opt.value"
          :aid="opt.aid"
          :value="opt.value"
        >
          {{ opt.label }}
        </WsSelectOption>
      </WsSelect>
    </div>

    <div class="toolbar-container_campaign-link">
      <input-select
        :model-value="model.banner?.linkUrl || ''"
        :placeholder="campaignLinkField.placeholder"
        :name="campaignLinkField.name"
        :label="campaignLinkField.label"
        :options="campaignLinkField.options.value"
        :toggle-aid="campaignLinkField.toggleAid"
        :input-aid="campaignLinkField.inputAid"
        :select-aid="campaignLinkField.selectAid"
        @update:model-value="handleCampaignLinkChange($event)"
      />

      <div
        v-if="model.banner?.linkUrl"
        class="toolbar-container_campaign-link_analytics-wrapper"
      >
        <CampaignAnalytics v-model="model.utmCampaign" :macros="macros" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  gap: 20px;
  min-height: min-content;

  &_time-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_date-pickers {
      display: flex;
      flex-direction: row;
      gap: 14px;
    }
  }

  .horizontal-label-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .horizontal-control {
    width: 160px;
  }

  .vertical-label-control {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
  }

  .grey-text {
    font-family: Mulish;
    font-size: 13px;
    font-weight: 400;
    color: #828282;
  }

  .horizontal-line {
    width: 100%;
    border-top: 1px solid #edeeef;
  }
}
</style>
