<script setup lang="ts">
import { defineProps, ref } from 'vue';

import { navigateTo } from '@mfl/framework';
import {
  WsMenu,
  WsIconButton,
  WsMenuItem,
  toast,
  ToastStatus,
} from '@mfl/common-components';
import { campaignGateway, ThinCampaign } from '@msl/campaign-gateway-sdk';

import eyeIcon from '../../assets/eye-icon.svg';
import strings from '../../campaigns.strings';
import { RowActionsParams } from '../../types';
import { openRenameCampaignDialog } from '../../components/rename-campaign-dialog';
import { openDeleteCampaignDialog } from './delete-campaign-dialog/delete-campaign-dialog';

const props = defineProps<{
  params: RowActionsParams;
}>();

const campaign = ref<ThinCampaign>(props.params?.data as ThinCampaign);
const status = ref<string>(
  props.params?.api.getCellValue({
    colKey: 'status',
    rowNode: props.params.node,
  }) as string
);
const showPreview = ref<boolean>(false);

function editCampaign() {
  navigateTo(`/v1/campaigns/editor/${campaign.value.campaignId}`);
}

async function renameCampaign() {
  const { success, title } = await openRenameCampaignDialog(campaign.value);

  if (success) {
    campaign.value.title = title;
    props.params.onCampaignRenamed?.(campaign.value.campaignId || '', title);
  }
}

async function togglePause() {
  try {
    const { isPaused = !campaign.value.isPaused } =
      await campaignGateway.togglePause({
        campaignId: campaign.value.campaignId || '',
      });

    campaign.value.isPaused = isPaused;
    status.value = isPaused ? strings.pausedStatus : strings.liveStatus;
    props.params.onCampaignPauseToggled?.(
      campaign.value.campaignId || '',
      isPaused
    );
  } catch (error) {
    toast({
      aid: 'UNEXPECTED_ERROR',
      message: strings.actionFailed,
      status: ToastStatus.Error,
    });

    console.error(error);
  }
}

async function endCampaign() {
  try {
    const { success } = await campaignGateway.endCampaign({
      campaignId: campaign.value.campaignId || '',
    });

    if (success) {
      campaign.value.endAt = Date.now();
      status.value = strings.endedStatus;
      props.params.onCampaignEnded?.(campaign.value.campaignId || '');
    }
  } catch (error) {
    toast({
      aid: 'UNEXPECTED_ERROR',
      message: strings.actionFailed,
      status: ToastStatus.Error,
    });

    console.error(error);
  }
}

async function deleteCampaign() {
  try {
    const result = await openDeleteCampaignDialog(campaign.value, status.value);

    if (result?.success) {
      props.params.onCampaignDeleted(campaign.value.campaignId || '');
    }
  } catch (error) {
    toast({
      aid: 'UNEXPECTED_ERROR',
      message: strings.actionFailed,
      status: ToastStatus.Error,
    });

    console.error(error);
  }
}

function updatePreviewPosition(event: MouseEvent) {
  const icon = event.currentTarget as HTMLElement;
  const rect = icon.getBoundingClientRect();

  // Position to the left of the icon, accounting for preview width
  document.documentElement.style.setProperty('--preview-top', `${rect.top}px`);
  document.documentElement.style.setProperty(
    '--preview-left',
    `${rect.left - 360}px`
  ); // 360px is preview width
}
</script>

<template>
  <div class="row-actions-container">
    <div
      class="preview-icon"
      @mouseenter="
        showPreview = true;
        updatePreviewPosition($event);
      "
      @mouseleave="showPreview = false"
    >
      <img :src="eyeIcon" alt="Banner preview" width="18" height="14" />
      <!-- Move the preview container to the end of body to avoid stacking context issues -->
      <Teleport to="body">
        <div
          v-if="campaign.banner?.imageUrl && showPreview"
          class="banner-preview-container"
          aid="CAMPAIGN_BANNER_PREVIEW_CONTAINER"
          :style="{
            top: `calc(var(--preview-top) + 25px)`,
            left: `calc(var(--preview-left) + 25px)`,
          }"
        >
          <img
            :src="campaign.banner?.imageUrl"
            :alt="campaign.title"
            class="banner-preview-image"
          />
        </div>
      </Teleport>
    </div>

    <WsIconButton
      variant="text"
      color="gray-400"
      :aid="`CAMPAIGN_ITEM_MENU_BUTTON_${campaign?.campaignId?.toUpperCase().replace(/\s/g, '_')}`"
    >
      <span class="fa-solid fa-ellipsis" />
      <WsMenu aid="CAMPAIGN_ITEM_MENU">
        <WsMenuItem aid="CAMPAIGN_ITEM_MENU_EDIT" @click="editCampaign">
          <div class="campaign-item-actions-icon-wrapper">
            <span class="fa-light fa-pen" />
            {{ strings.editAction }}
          </div>
        </WsMenuItem>
        <WsMenuItem aid="CAMPAIGN_ITEM_MENU_RENAME" @click="renameCampaign">
          <div class="campaign-item-actions-icon-wrapper">
            <span class="fa-light fa-pen-to-square" />
            {{ strings.renameAction }}
          </div>
        </WsMenuItem>
        <WsMenuItem
          v-if="status === strings.liveStatus"
          aid="CAMPAIGN_ITEM_MENU_PAUSE"
          @click="togglePause"
        >
          <div class="campaign-item-actions-icon-wrapper">
            <span class="fa-light fa-circle-pause" />
            {{ strings.pauseAction }}
          </div>
        </WsMenuItem>
        <WsMenuItem
          v-if="status === strings.pausedStatus"
          aid="CAMPAIGN_ITEM_MENU_RESUME"
          @click="togglePause"
        >
          <div class="campaign-item-actions-icon-wrapper">
            <span class="fa-light fa-arrow-rotate-right" />
            {{ strings.resumeAction }}
          </div>
        </WsMenuItem>
        <WsMenuItem
          v-if="status === strings.liveStatus"
          aid="CAMPAIGN_ITEM_MENU_END"
          @click="endCampaign"
        >
          <div class="campaign-item-actions-icon-wrapper">
            <span class="fa-light fa-circle-xmark" />
            {{ strings.endAction }}
          </div>
        </WsMenuItem>
        <WsMenuItem aid="CAMPAIGN_ITEM_MENU_DELETE" @click="deleteCampaign">
          <div class="campaign-item-actions-icon-wrapper">
            <span class="fa-light fa-trash-can" />
            {{ strings.deleteAction }}
          </div>
        </WsMenuItem>
      </WsMenu>
    </WsIconButton>
  </div>
</template>

<style lang="scss" scoped>
.row-actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  height: 100%;
  min-width: 80px;

  .preview-icon {
    height: 14px;
    width: 18px;
    cursor: pointer;
    position: relative;

    &:hover .banner-preview-container {
      top: calc(var(--preview-top) + 50px);
      left: var(--preview-left);
    }
  }
}

.campaign-item-actions-icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.banner-preview-container {
  position: fixed;
  width: 360px;
  overflow: hidden;
  border-radius: 2px;
  background-color: transparent;
  z-index: 999;
  pointer-events: none;
}
</style>
