<script setup lang="ts">
import { computed } from 'vue';

import { Macros } from '@msl/campaign-gateway-sdk';

import AnalyticsFields from './analytics-fields.vue';
import strings from '../../campaigns.strings';
import { AnalyticsModel, DeepRequired } from '../../types';
import { mapPropertyToSelectOptionMacros } from '../../utils';

const model = defineModel<AnalyticsModel>({
  required: true,
});

const props = defineProps<{
  isLoading: boolean;
  macros: DeepRequired<Macros>;
}>();

const analyticsGroupedFields = computed(() => {
  return [
    {
      header: strings.analyticsGeneralCompanyFieldSection,
      options: props.macros?.company
        ?.filter((m) => m.fieldType === 'text' && m.property !== undefined)
        .map((m) => mapPropertyToSelectOptionMacros('company', m)),
    },
    {
      header: strings.analyticsGeneralEmployeeFieldSection,
      options: props.macros?.employee
        ?.filter((m) => m.fieldType === 'text' && m.property !== undefined)
        .map((m) => mapPropertyToSelectOptionMacros('employee', m)),
    },
  ];
});

const fieldsData = [
  {
    label: strings.analyticsGeneralCompanyFieldCampaignNameLabel,
    name: 'name' as keyof AnalyticsModel,
    placeholder: 'e.g., email, banner, cpc',
    options: analyticsGroupedFields,
    toggleLabel: strings.useVariable,
    toggleAid: 'CAMPAIGN_NAME_USE_VARIABLE',
    inputAid: 'CAMPAIGN_NAME_INPUT',
    selectAid: 'CAMPAIGN_NAME_SELECT',
  },
  {
    label: strings.analyticsGeneralCompanyFieldCampaignMediumLabel,
    name: 'medium' as keyof AnalyticsModel,
    placeholder: 'e.g., summer-sale, product-launch',
    options: analyticsGroupedFields,
    toggleLabel: strings.useVariable,
    toggleAid: 'CAMPAIGN_MEDIUM_USE_VARIABLE',
    inputAid: 'CAMPAIGN_MEDIUM_INPUT',
    selectAid: 'CAMPAIGN_MEDIUM_SELECT',
  },
  {
    label: strings.analyticsGeneralCompanyFieldCampaignSourceLabel,
    name: 'source' as keyof AnalyticsModel,
    placeholder: 'e.g., newsletter, social, email',
    toggleLabel: strings.useVariable,
    options: analyticsGroupedFields,
    toggleAid: 'CAMPAIGN_SOURCE_USE_VARIABLE',
    inputAid: 'CAMPAIGN_SOURCE_INPUT',
    selectAid: 'CAMPAIGN_SOURCE_SELECT',
  },
];

const handleUpdateModelValue = (v: AnalyticsModel) => {
  model.value = v;
};
</script>

<template>
  <div class="analytics-wrapper">
    <analytics-fields
      :fields-data="fieldsData"
      :model-value="model"
      @update:model-value="handleUpdateModelValue"
    />
  </div>
</template>
