<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import {
  navigateTo,
  preventPageUnload,
  releasePageUnload,
} from '@mfl/framework';
import { HeaderMode, headerMode } from '@mfe/core-header';
import { NavigationBarMode, navigationBarMode } from '@mfe/core-navigation';
import { ToastStatus, WsButton, toast } from '@mfl/common-components';
import { currentUser, trackEvent } from '@mfl/platform-shell';
import {
  campaignGateway,
  Category,
  Employee,
  GetCampaignByIdResponse,
  Group,
  Macros,
  Position,
  Priority,
  UpdateRequest,
} from '@msl/campaign-gateway-sdk';

import SignaturePreview from './components/signature-preview.vue';
import CampaignToolbar from './components/campaign-toolbar.vue';

import { openRenameCampaignDialog } from '../components/rename-campaign-dialog';
import strings from '../campaigns.strings';
import { DeepRequired } from '../types';
import { DEFAULT_CAMPAIGN_STATE, positionMap, priorityMap } from '../constants';
import { deepClone, hasUnsavedChanges } from '../utils';
import {
  openSaveValidationDialog,
  saveValidationDialogDataMapper,
} from './components/save-validation-dialog/save-validation-dialog';

const route = useRoute();
// Need to store additionally initial campaign state to check if there are unsaved changes
const initialCampaignState = ref<GetCampaignByIdResponse & UpdateRequest>(
  deepClone(DEFAULT_CAMPAIGN_STATE)
);
const campaign = ref<GetCampaignByIdResponse & UpdateRequest>(
  deepClone(DEFAULT_CAMPAIGN_STATE)
);

const isCampaignValid = computed(
  () =>
    Boolean(campaign.value.banner?.imageUrl) &&
    campaign.value.endAt &&
    campaign.value.startAt &&
    new Date(campaign.value.endAt) > new Date(campaign.value.startAt)
);

const isCampaignLive = computed(() => {
  const dateNow = new Date();
  const startAt = new Date(campaign.value?.startAt || '');
  const endAt = new Date(campaign.value?.endAt || '');

  if (startAt > dateNow || endAt < dateNow || campaign.value?.isPaused) {
    return false;
  }

  return startAt <= dateNow && endAt > dateNow;
});

const campaignData = computed(() => ({
  banner: { ...campaign.value.banner },
  position: campaign.value.position,
  keepBanners: campaign.value.keepBanners,
  utmCampaign: campaign.value.utmCampaign,
}));

const isLoaded = ref<boolean>(false); // Used to prevent page unload when the campaign editor is opened
const originalGroupIds = ref<string[]>([]);
const groups = ref<DeepRequired<Group>[]>([]);
const employees = ref<DeepRequired<Employee>[]>([]);
const bannerApps = ref<DeepRequired<Category>[]>([]);
const macros = ref<DeepRequired<Macros>>({ company: [], employee: [] });
const isHandlingNavigation = ref(false);

async function openValidationDialog(): Promise<void> {
  const isBannerMissing = !campaign.value.banner?.imageUrl;
  const isEndDateMissing = !campaign.value.endAt;
  const isEndDateBeforeStartDate =
    campaign.value.endAt &&
    campaign.value.startAt &&
    new Date(campaign.value.endAt) < new Date(campaign.value.startAt);

  if (isBannerMissing) {
    await openSaveValidationDialog(
      saveValidationDialogDataMapper.bannerMissing
    );
  } else if (isEndDateMissing) {
    await openSaveValidationDialog(
      saveValidationDialogDataMapper.endDateMissing
    );
  } else if (isEndDateBeforeStartDate) {
    await openSaveValidationDialog(
      saveValidationDialogDataMapper.endDateBeforeStartDate
    );
  }
}

async function loadCampaignData(): Promise<void> {
  if (route.params.campaignId) {
    campaign.value = await campaignGateway.getCampaignById({
      campaignId: route.params.campaignId as string,
    });

    originalGroupIds.value = campaign.value.groupIds || [];
    initialCampaignState.value = deepClone(campaign.value);
  }

  isLoaded.value = true;

  const [groupsRes, employeesRes, bannerAppsRes, macrosRes] =
    await Promise.allSettled([
      campaignGateway.getGroupsByDomain({}),
      campaignGateway.getEmployeesByDomain({}),
      campaignGateway.getBannerApps({}),
      campaignGateway.getMacros({}),
    ]);

  groups.value = (
    groupsRes.status === 'fulfilled' ? groupsRes.value.groups : []
  ) as DeepRequired<Group>[];
  employees.value = (
    employeesRes.status === 'fulfilled' ? employeesRes.value.employees : []
  ) as DeepRequired<Employee>[];
  bannerApps.value = (
    bannerAppsRes.status === 'fulfilled' ? bannerAppsRes.value.categories : []
  ) as DeepRequired<Category>[];
  macros.value = (
    macrosRes.status === 'fulfilled'
      ? macrosRes.value.macros
      : {
          company: [],
          employee: [],
        }
  ) as DeepRequired<Macros>;
}

async function saveCampaign(): Promise<{ success: boolean }> {
  if (!isCampaignValid.value) {
    await openValidationDialog();
    return { success: false };
  }

  if (isCampaignLive.value) {
    const agree = await openSaveValidationDialog(
      saveValidationDialogDataMapper.goLive
    );
    if (!agree) return { success: false };
  }

  try {
    let success = false;

    if (campaign.value.campaignId) {
      ({ success = false } = await campaignGateway.update(campaign.value));
    } else {
      ({ success } = await renameCampaign());

      if (success) {
        trackEvent({
          eventName: 'campign_created',
          eventMetadata: {
            action: 'Creation',
            category: 'Funnel',
            label: campaign.value.title,
            attributes: {
              account_id: currentUser.accountId,
              start_date:
                campaign.value.startAt || Date.now() > Date.now()
                  ? 'future'
                  : 'current',
              creation_date: Date.now(),
              Remove_other_banners: !campaign.value.keepBanners,
              assign_to: campaign.value.groupIds?.length
                ? 'all groups'
                : 'specific groups',
              group_count: campaign.value.groupIds?.length,
              priority: priorityMap[campaign.value.priority || Priority.MEDIUM],
              banner_position:
                positionMap[campaign.value.position || Position.BELOW],
              banner_size: campaign.value.banner?.bannerSize,
              Campaign_link: !!campaign.value.banner?.linkUrl,
              Campaign_UTM: campaign.value.utmCampaign
                ? 'specific'
                : 'inherited',
            },
          },
        });
      }
    }

    if (success) {
      // Update the initial state to match the current state after successful save
      initialCampaignState.value = deepClone(campaign.value);
      // Explicitly release page unload prevention
      releasePageUnload();
      navigateTo('/v1/campaigns');
    }

    return { success };
  } catch (error) {
    trackEvent({
      eventName: 'campaign_editor_save_error',
      eventMetadata: {
        action: campaign.value.campaignId
          ? 'Update Campaign'
          : 'Create Campaign',
        category: 'Errors',
        label: campaign.value.title,
        attributes: {
          error_message: (error as Error).message,
        },
      },
    });

    toast({
      aid: 'UNEXPECTED_ERROR',
      message: strings.saveFailed,
      status: ToastStatus.Error,
    });

    console.error('An error occurred while saving the campaign', error);
    return { success: false };
  }
}

async function saveAndNavigate() {
  const saveResult = await saveCampaign();
  if (!saveResult?.success) {
    return;
  }

  isHandlingNavigation.value = true;
  navigateTo('/v1/campaigns');
}

const originalNavBarMode = navigationBarMode.value;
const originalHeaderMode = headerMode.value;

// Hide the navigation bar and header when the campaign editor is opened (it behaves like created hook)
navigationBarMode.value = NavigationBarMode.Hidden;
headerMode.value = HeaderMode.Hidden;

// Lifecycle hooks
onMounted(async () => {
  await loadCampaignData();
});

onUnmounted(() => {
  headerMode.value = originalHeaderMode;
  navigationBarMode.value = originalNavBarMode;

  releasePageUnload();
});

// Track group removals and save them to the campaign object. Needed for syncing groups
watch(
  () => campaign.value.groupIds,
  (newGroupIds) => {
    if (!route.params.campaignId) return;

    const removedIds = originalGroupIds.value.filter(
      (id) => !newGroupIds?.includes(id)
    );

    campaign.value.removedGroupIds =
      removedIds.length > 0 ? removedIds : undefined;
  }
);

// Prevent page unload when there are unsaved changes
watch(
  () => campaign.value,
  () => {
    if (!isLoaded.value) return;

    if (hasUnsavedChanges(initialCampaignState.value, campaign.value)) {
      preventPageUnload();
    } else {
      releasePageUnload();
    }
  },
  { deep: true }
);

async function navigateBack() {
  navigateTo('/v1/campaigns');
}

async function renameCampaign(): Promise<{ success: boolean; title: string }> {
  return await openRenameCampaignDialog(campaign.value);
}
</script>

<template>
  <div class="campaign-editor">
    <div class="campaign-editor_header">
      <div class="back-button">
        <WsButton
          aid="CAMPAIGNS_EDITOR_HEADER_BACK"
          label="Back"
          icon="fa-regular fa-chevron-left"
          variant="text"
          color="gray-500"
          type="button"
          @click="navigateBack"
        />
        <div v-if="campaign.title" class="back-button_title">
          |
          <span>{{ campaign.title }}</span>
        </div>
      </div>
      <WsButton
        label="Save"
        color="primary"
        size="sm"
        aid="CAMPAIGN_EDITOR_HEADER_SAVE_BUTTON"
        @click="saveAndNavigate"
      />
    </div>

    <div class="campaign-editor_content">
      <div class="toolbar">
        <CampaignToolbar
          :model-value="campaign"
          :groups="groups"
          :macros="macros"
          :banner-apps="bannerApps"
        />
      </div>

      <div class="preview">
        <SignaturePreview
          :groups="groups"
          :employees="employees"
          :campaign-data="campaignData"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.campaign-editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &_header {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #e0e0e0;
    background: #fff;

    .back-button {
      display: flex;
      align-items: center;
      gap: 20px;

      &_title {
        display: flex;
        align-items: center;
        gap: 20px;
        font-feature-settings: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-size: 14px;
        font-size-adjust: none;
        font-stretch: 100%;
        font-style: normal;
        font-variant-alternates: normal;
        font-variant-caps: normal;
        font-variant-east-asian: normal;
        font-variant-emoji: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-variant-position: normal;
        font-variation-settings: normal;
        font-weight: 600;
      }
    }
  }

  &_content {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    height: 100%;
    width: 100%;

    .toolbar {
      display: flex;
      width: 420px;
      // 65px is the height of the header + the toolbar's padding
      height: calc(100% - 65px);
      overflow-y: auto;
      border-right: 1px solid #edeeef;
    }

    .preview {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      background-color: #f4f6f8;
      height: 100%;
    }
  }
}
</style>
