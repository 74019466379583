<script setup lang="ts">
import { ref, onMounted } from 'vue';

import { WsSelect, WsSelectOption } from '@mfl/common-components';
import { UtmCampaign, Macros } from '@msl/campaign-gateway-sdk';

import strings from '../../campaigns.strings';
import AnalyticsWrapper from './analytics-wrapper.vue';
import { DeepRequired } from '../../types';

const props = defineProps<{
  modelValue?: UtmCampaign;
  macros: DeepRequired<Macros>;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: UtmCampaign | undefined];
}>();

const analyticsVariant = ref<'custom' | 'inherit'>('inherit');

onMounted(() => {
  analyticsVariant.value =
    props.modelValue?.medium ||
    props.modelValue?.source ||
    props.modelValue?.name
      ? 'custom'
      : 'inherit';
});

const analyticsVariantOptions = [
  {
    label: strings.setSpecificForTheCampaign,
    value: 'custom',
    aid: 'CAMPAIGN_EDITOR_ANALYTICS_VARIANT_SET_SPECIFIC_FOR_THE_CAMPAIGN',
  },
  {
    label: strings.inheritFromAnalytics,
    value: 'inherit',
    aid: 'CAMPAIGN_EDITOR_ANALYTICS_VARIANT_INHERIT_FROM_ANALYTICS',
  },
];

function handleUpdateAnalyticsVariant(value: unknown) {
  analyticsVariant.value = value as 'custom' | 'inherit';

  if (value === 'inherit') {
    emit('update:modelValue', {
      ...props.modelValue,
      medium: undefined,
      source: undefined,
      name: undefined,
    });
  }
}

function handleUpdateAnalytics(value: unknown) {
  emit('update:modelValue', value as UtmCampaign);
}
</script>

<template>
  <div class="campaign-analytics">
    <div class="vertical-label-control">
      <div class="grey-text">{{ strings.googleAnalyticsUTM }}</div>
      <WsSelect
        size="md"
        :option-key="(val: 'custom' | 'inherit') => val"
        :option-label="
          (v: 'custom' | 'inherit') =>
            v === 'custom'
              ? strings.setSpecificForTheCampaign
              : strings.inheritFromAnalytics
        "
        :model-value="analyticsVariant"
        aid="CAMPAIGN_EDITOR_ANALYTICS_VARIANT"
        @update:model-value="handleUpdateAnalyticsVariant"
      >
        <WsSelectOption
          v-for="opt in analyticsVariantOptions"
          :key="opt.value"
          :aid="opt.aid"
          :value="opt.value"
        >
          {{ opt.label }}
        </WsSelectOption>
      </WsSelect>
    </div>

    <AnalyticsWrapper
      v-if="analyticsVariant === 'custom'"
      :model-value="modelValue || {}"
      :is-loading="false"
      :macros="macros"
      @update:model-value="handleUpdateAnalytics"
    />
  </div>
</template>

<style lang="scss" scoped>
.campaign-analytics {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vertical-label-control {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
}

.grey-text {
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  color: #828282;
}
</style>
