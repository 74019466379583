<script setup lang="ts">
import { defineProps, ref } from 'vue';

import strings from '../../campaigns.strings';

const props = defineProps<{
  params: Record<string, unknown>;
}>();

const status = ref(props.params?.value);
</script>
<template>
  <div v-if="status === strings.liveStatus" class="live-status-wrapper">
    <div class="circle" />
    {{ status }}
  </div>
  <div v-else>{{ status }}</div>
</template>

<style scoped>
.live-status-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3px;

  .circle {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: #1e90fa;
    border: 3px solid #e1ebff;
  }
}
</style>
