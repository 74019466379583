import {
  themeQuartz,
  iconSetMaterial,
  AllCommunityModule,
  ModuleRegistry,
} from 'ag-grid-community';

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

export const WSAgTheme = themeQuartz.withPart(iconSetMaterial).withParams({
  accentColor: '#1E90FA',
  backgroundColor: '#FFFFFF',
  borderColor: '#E0E0E0',
  borderRadius: '6px',
  browserColorScheme: 'dark',
  cellHorizontalPadding: '24px',
  cellHorizontalPaddingScale: 1,
  checkboxBorderRadius: '3px',
  checkboxCheckedBackgroundColor: '#1E90FA',
  checkboxCheckedBorderColor: '#1E90FA',
  checkboxCheckedShapeColor: '#FFFFFF',
  chromeBackgroundColor: '#FFFFFF',
  columnBorder: false,
  columnDropCellBackgroundColor: '#FFFFFF',
  focusShadow: '#1E90FA80 0px 0px 0px 0px',
  fontFamily: ['Mulish', 'sans-serif'],
  fontSize: '14px',
  footerRowBorder: true,
  foregroundColor: '#2D2E30',
  headerHeight: 38,
  headerBackgroundColor: '#F4F6F8',
  headerRowBorder: false,
  headerFontSize: '14px',
  headerFontWeight: 700,
  headerTextColor: '#2D2E30',
  headerVerticalPaddingScale: 0.5,
  iconButtonHoverColor: '#EDEEEF',
  menuBackgroundColor: '#FFFFFF',
  menuBorder: false,
  menuSeparatorColor: '#E0E0E0',
  menuTextColor: '#2D2E30',
  rowBorder: true,
  rowHeight: '50px',
  rowHoverColor: '#F4F6F8',
  rowVerticalPaddingScale: 0.75,
  selectedRowBackgroundColor: '#E1EBFF',
  sideBarBackgroundColor: '#FFFFFF',
  sidePanelBorder: false,
  spacing: '14px',
  tooltipBackgroundColor: '#2D2E30',
  tooltipTextColor: '#FFFFFF',
  wrapperBorder: false,
  wrapperBorderRadius: '6px',
});
