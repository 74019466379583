import { openDialog } from '@mfl/common-components';

import SaveValidationDialog from './save-validation-dialog.vue';
import strings from '../../../campaigns.strings';

export interface SaveValidationDialogInput {
  message: string;
  saveBtn: {
    aid: string;
    text: string;
  };
  cancelBtn: {
    aid: string;
    text: string;
  };
}

export interface SaveValidationDialogData {
  input: SaveValidationDialogInput;
  aid: string;
  headerText: string;
}

export function openSaveValidationDialog(
  data: SaveValidationDialogData
): Promise<boolean | undefined> {
  return openDialog<SaveValidationDialogInput, boolean>({
    ...data,
    component: SaveValidationDialog,
    showTopSeparator: false,
  });
}

export const saveValidationDialogDataMapper: Record<
  'goLive' | 'bannerMissing' | 'endDateMissing' | 'endDateBeforeStartDate',
  SaveValidationDialogData
> = {
  goLive: {
    input: {
      message: strings.goLiveMessage,
      saveBtn: { aid: 'GO_LIVE_DIALOG_SAVE_BTN', text: strings.goLiveBtn },
      cancelBtn: { aid: 'GO_LIVE_DIALOG_CANCEL_BTN', text: strings.discard },
    },
    aid: 'GO_LIVE_DIALOG',
    headerText: strings.goLiveTitle,
  },
  bannerMissing: {
    input: {
      message: strings.validationDialogBannerMissingMessage,
      saveBtn: {
        aid: 'VALIDATION_DIALOG_BANNER_MISSING_SAVE_BTN',
        text: strings.validationDialogSaveBtn,
      },
      cancelBtn: {
        aid: 'VALIDATION_DIALOG_BANNER_MISSING_CANCEL_BTN',
        text: strings.discard,
      },
    },
    aid: 'VALIDATION_DIALOG_BANNER_MISSING',
    headerText: strings.validationDialogBannerMissingTitle,
  },
  endDateMissing: {
    input: {
      message: strings.validationDialogEndDateMessage,
      saveBtn: {
        aid: 'VALIDATION_DIALOG_END_DATE_MISSING_SAVE_BTN',
        text: strings.validationDialogSaveBtn,
      },
      cancelBtn: {
        aid: 'VALIDATION_DIALOG_END_DATE_MISSING_CANCEL_BTN',
        text: strings.discard,
      },
    },
    aid: 'VALIDATION_DIALOG_END_DATE_MISSING',
    headerText: strings.validationDialogEndDateTitle,
  },
  endDateBeforeStartDate: {
    input: {
      message: strings.validationDialogEndDateBeforeStartDateMessage,
      saveBtn: {
        aid: 'VALIDATION_DIALOG_END_DATE_BEFORE_START_DATE_SAVE_BTN',
        text: strings.validationDialogSaveBtn,
      },
      cancelBtn: {
        aid: 'VALIDATION_DIALOG_END_DATE_BEFORE_START_DATE_CANCEL_BTN',
        text: strings.discard,
      },
    },
    aid: 'VALIDATION_DIALOG_END_DATE_BEFORE_START_DATE',
    headerText: strings.validationDialogEndDateBeforeStartDateTitle,
  },
};
