<script setup lang="ts">
import {
  WsButton,
  IDialog,
  WsDialogActions,
  WsDialogSection,
  WsDialogForm,
} from '@mfl/common-components';

import { SaveValidationDialogInput } from './save-validation-dialog';

const { dialog } = defineProps<{
  dialog: IDialog<SaveValidationDialogInput, boolean>;
}>();

function positiveAction(): void {
  dialog.close(true);
}

function closeMe(): void {
  dialog.close(false);
}
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="campaign-delete-confirmation-section">
      {{ dialog.input?.message }}
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        :aid="dialog.input?.cancelBtn.aid"
        variant="outlined"
        color="gray-500"
        @click="closeMe"
      >
        {{ dialog.input?.cancelBtn.text }}
      </WsButton>
      <WsButton
        :aid="dialog.input?.saveBtn.aid"
        variant="contained"
        color="primary"
        @click="positiveAction"
      >
        {{ dialog.input?.saveBtn.text }}
      </WsButton>
    </WsDialogActions>
  </WsDialogForm>
</template>

<style lang="scss">
.campaign-delete-confirmation-section {
  width: 400px;
  padding: 0 24px 14px 24px !important;
}
</style>
