import { auth4Sdks } from '@mfl/platform-shell';
import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';
import { campaignGateway } from '@msl/campaign-gateway-sdk';

import mfe from './campaigns-mfe.vue';
import { router } from './router';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'campaigns',
  mainComponent: mfe,
  targetElement: '#content',
  includeCss: true,
  handleVueApp: (app) => {
    useQuasarPlugin(app);

    campaignGateway.init(auth4Sdks());

    app.use(router);
  },
});

export { bootstrap, mount, unmount };
