import { openDialog } from '@mfl/common-components';
import { Campaign } from '../../../types';
import { DeleteResponse } from '@msl/campaign-gateway-sdk';
import strings from '../../../campaigns.strings';
import DeleteCampaignDialog from './delete-campaign-dialog.vue';

type DeleteCampaignDialogInput = Campaign & { status: string };

export async function openDeleteCampaignDialog(
  campaign: Campaign,
  status: string
): Promise<DeleteResponse | undefined> {
  return await openDialog<DeleteCampaignDialogInput, DeleteResponse>({
    aid: 'DELETE_CAMPAIGN_DIALOG',
    headerText:
      status === strings.liveStatus
        ? strings.deleteLiveCampaignModalTitle
        : strings.deleteCampaignModalTitle,
    component: DeleteCampaignDialog,
    input: { ...campaign, status },
    showTopSeparator: false,
  });
}
