<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import * as UC from '@uploadcare/file-uploader';
import '@uploadcare/file-uploader/web/uc-file-uploader-regular.min.css';

import {
  WsIconButton,
  WsMenu,
  WsMenuItem,
  toast,
  ToastStatus,
} from '@mfl/common-components';
import { navigateTo } from '@mfl/framework';
import { campaignGateway, Category } from '@msl/campaign-gateway-sdk';

import strings from '../../campaigns.strings';
import { DeepRequired } from '../../types';
import { openBannerGalleryDialog } from './banner-gallery-dialog/banner-gallery-dialog';

UC.defineComponents(UC);

const uploadcarePubkey = import.meta.env.VITE_UPLOADCARE_PUBKEY;
const IMAGE_BASE_URL = 'https://d36urhup7zbd7q.cloudfront.net';
const props = defineProps<{
  modelValue: {
    imageUrl?: string;
    linkUrl: string;
    bannerSize: string;
    alignment: string;
  };
  bannerApps: DeepRequired<Category>[];
}>();

const emit = defineEmits<{
  'update:modelValue': [value: typeof props.modelValue];
}>();

const uploaderCtx = ref<InstanceType<UC.UploadCtxProvider> | null>(null);
const fileMimeType = ref<string>();

async function onFileAdded(event: CustomEvent<UC.EventPayload['file-added']>) {
  fileMimeType.value = event.detail.mimeType;
}

async function onDoneClick(event: CustomEvent<UC.EventPayload['done-click']>) {
  const entry = event.detail.successEntries[0];

  try {
    const res = await campaignGateway.uploadBanner({
      uuid: entry.uuid,
      cdnUrl: entry.cdnUrl,
      imageFormat: entry.fileInfo.imageInfo?.format,
    });

    if (!res.objectKey) {
      toast({
        aid: 'UNEXPECTED_ERROR',
        message: strings.actionFailed,
        status: ToastStatus.Error,
      });

      return;
    }

    emit('update:modelValue', {
      ...props.modelValue,
      imageUrl: new URL(res.objectKey, IMAGE_BASE_URL).href,
    });
  } catch (error) {
    toast({
      aid: 'UNEXPECTED_ERROR',
      message: strings.actionFailed,
      status: ToastStatus.Error,
    });

    throw error;
  }
}

onMounted(() => {
  if (!uploaderCtx.value) return;

  uploaderCtx.value.addEventListener('file-added', onFileAdded);
  uploaderCtx.value.addEventListener('done-click', onDoneClick);
});

onUnmounted(() => {
  if (!uploaderCtx.value) return;

  uploaderCtx.value.removeEventListener('file-added', onFileAdded);
  uploaderCtx.value.removeEventListener('done-click', onDoneClick);
});

function uploadBanner() {
  uploaderCtx.value?.getAPI().removeAllFiles();
  uploaderCtx.value?.getAPI().initFlow();
}

async function selectFromGallery() {
  const bannerImgUrl = await openBannerGalleryDialog({
    banners: props.bannerApps,
  });

  if (bannerImgUrl) {
    emit('update:modelValue', {
      ...props.modelValue,
      imageUrl: bannerImgUrl,
    });
  }
}

function createABanner() {
  navigateTo('https://www.wisestamp.com/banner-maker/ ', {
    reload: true,
    newTab: true,
  });
}

function removeBanner() {
  emit('update:modelValue', {
    ...props.modelValue,
    imageUrl: undefined,
  });
}
</script>

<template>
  <div
    :class="{
      'toolbar-container_banner': true,
      'with-image': modelValue.imageUrl,
    }"
  >
    <uc-config
      ctx-name="banner-uploader"
      :pubkey="uploadcarePubkey"
      multiple="false"
      img-only="true"
      source-list="local, url, dropbox, gdrive"
      max-local-file-size-bytes="10485760"
      :use-cloud-image-editor="true"
    />

    <uc-upload-ctx-provider ref="uploaderCtx" ctx-name="banner-uploader" />

    <uc-file-uploader-regular
      ctx-name="banner-uploader"
      headless="true"
      class="uc-light"
      :preview-step="true"
      :crop="true"
      :tabs="['file', 'url', 'camera']"
    />

    <div
      v-if="!modelValue.imageUrl"
      class="toolbar-container_banner_empty-state"
    >
      <WsIconButton
        class="toolbar-container_banner_icon-wrapper"
        variant="contained"
        color="primary"
        aid="CAMPAIGN_EDITOR_ADD_BANNER"
      >
        <span class="fa-light fa-plus" />
        <WsMenu aid="CAMPAIGN_EDITOR_ADD_BANNER_MENU">
          <WsMenuItem
            aid="CAMPAIGN_EDITOR_ADD_BANNER_MENU_UPLOAD"
            @click="uploadBanner"
          >
            <div class="toolbar-container_banner_icon-wrapper">
              <span class="fa-light fa-upload" />
              {{ strings.uploadYourOwn }}
            </div>
          </WsMenuItem>
          <WsMenuItem
            aid="CAMPAIGN_EDITOR_ADD_BANNER_MENU_GALLERY"
            @click="selectFromGallery"
          >
            <div class="toolbar-container_banner_icon-wrapper">
              <span class="fa-light fa-rectangle-history" />
              {{ strings.selectFromGallery }}
            </div>
          </WsMenuItem>
          <WsMenuItem
            aid="CAMPAIGN_EDITOR_ADD_BANNER_MENU_CREATE"
            @click="createABanner"
          >
            <div class="toolbar-container_banner_icon-wrapper">
              <span class="fa-light fa-pen-fancy" />
              {{ strings.createABanner }}
            </div>
          </WsMenuItem>
        </WsMenu>
      </WsIconButton>
      <span class="toolbar-container_banner_empty-state_text">
        {{ strings.addABanner }}
      </span>
    </div>

    <div v-else class="toolbar-container_banner_image-wrapper">
      <img :src="modelValue.imageUrl" alt="Banner" class="banner-image" />
      <div class="toolbar-container_banner_image-wrapper_remove-button">
        <WsIconButton
          variant="text"
          color="gray-400"
          size="sm"
          aid="CAMPAIGN_EDITOR_REMOVE_BANNER"
          @click="removeBanner"
        >
          <span class="fa-light fa-trash-can" />
        </WsIconButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar-container_banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 1px;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  height: 100px;
  position: relative;

  &_empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;

    &_text {
      font-family: Mulish;
      font-size: 13px;
      font-weight: 700;
      line-height: normal;
    }
  }

  &.with-image {
    padding: 0px;
    height: 100px;
  }

  &_icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &_image-wrapper {
    width: 100%;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f5f5f5;
    border-radius: 6px;

    .banner-image {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
    }

    &_remove-button {
      position: absolute;
      top: 6px;
      right: 6px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      border-radius: 4px;
      border: 1px solid var(--Gray-200, #e0e0e0);
      background: #fff;
    }

    &:hover {
      .toolbar-container_banner_image-wrapper_remove-button {
        opacity: 1;
      }
    }
  }
}
</style>
