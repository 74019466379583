<script lang="ts" setup>
import { defineProps, computed } from 'vue';
import { WSAgTheme } from './ag-grid-theme-builder';
import { AgGridVue } from 'ag-grid-vue3';
import {
  ColDef,
  DomLayoutType,
  SizeColumnsToFitGridStrategy,
} from 'ag-grid-community';

const props = defineProps<{
  rowData: Record<string, unknown>[];
  columnDefs: ColDef[];
  customComponents?: Record<string, unknown>;
  sizeColumnsToFit?: SizeColumnsToFitGridStrategy;
}>();

const attrs = computed(() => ({
  theme: WSAgTheme,
  columnDefs: props.columnDefs,
  rowData: props.rowData,
  domLayout: 'autoHeight' as DomLayoutType,
  suppressCellFocus: true,
  defaultColDef: { flex: 1, suppressMovable: true, resizable: false },
  class: 'ws-grid',
  sizeColumnsToFit: props.sizeColumnsToFit,
}));

defineExpose(props.customComponents);
</script>

<template>
  <AgGridVue v-bind="attrs"> </AgGridVue>
</template>

<style lang="scss" scoped>
// .ws-grid {

// }
</style>
