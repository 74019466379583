import { openDialog } from '@mfl/common-components';

import strings from '../campaigns.strings';
import RenameCampaignDialog from './rename-campaign-dialog.vue';
import { Campaign } from '../types';

export type RenameCampaignDialogResult = { success: boolean; title: string };

export async function openRenameCampaignDialog(
  value: Campaign
): Promise<RenameCampaignDialogResult> {
  const res = await openDialog<Campaign, RenameCampaignDialogResult>({
    aid: 'RENAME_CAMPAIGN_DIALOG',
    headerText: strings.campaignNameModalTitle,
    component: RenameCampaignDialog,
    input: value,
    showTopSeparator: false,
  });

  if (res === undefined) return { success: false, title: '' };

  return res;
}
