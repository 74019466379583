import { Category } from '@msl/campaign-gateway-sdk';
import { openDialog } from '@mfl/common-components';

import BannerGalleryDialog from './banner-gallery-dialog.vue';
import strings from '../../../campaigns.strings';

export interface BannerGalleryDialogInput {
  banners: Category[];
}

export interface BannerGalleryDialogOutput {
  selectedImageUrl: string;
}

export function openBannerGalleryDialog(
  input: BannerGalleryDialogInput
): Promise<string | undefined> {
  return openDialog<BannerGalleryDialogInput, string>({
    aid: 'BANNER_GALLERY_DIALOG',
    headerText: strings.bannerGalleryDialogTitle,
    component: BannerGalleryDialog,
    input,
    showTopSeparator: true,
  });
}
