<script setup lang="ts">
const model = defineModel<'left' | 'center' | 'right'>();

const alignmentOptions = [
  {
    value: 'left',
    aid: 'CAMPAIGN_EDITOR_ALIGN_LEFT',
    icon: 'fa-light fa-objects-align-top fa-rotate-180',
  },
  {
    value: 'center',
    aid: 'CAMPAIGN_EDITOR_ALIGN_CENTER',
    icon: 'fa-light fa-objects-align-center-vertical fa-rotate-180',
  },
  {
    value: 'right',
    aid: 'CAMPAIGN_EDITOR_ALIGN_RIGHT',
    icon: 'fa-light fa-objects-align-top fa-flip-horizontal',
  },
];
</script>

<template>
  <div class="alignment-buttons">
    <button
      v-for="option in alignmentOptions"
      :key="option.value"
      type="button"
      class="alignment-button"
      :class="{ active: model === option.value }"
      :aid="option.aid"
      @click="model = option.value as 'left' | 'center' | 'right'"
    >
      <span class="fa-rotate-90" style="display: inline-block">
        <i :class="option.icon" />
      </span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.alignment-buttons {
  display: flex;
  height: 31px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;
}

.alignment-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: none;
  cursor: pointer;
  color: #2d2e30;
  padding: 0;
  transition: all 0.2s ease;

  &:not(:last-child) {
    border-right: 1px solid #e0e0e0;
  }

  i {
    font-size: 16px;
  }

  &:hover {
    background: #f8f8f8;
  }

  &.active {
    color: #1e90fa;
  }
}
</style>
