<script setup lang="ts">
import { computed, ref } from 'vue';

import { IDialog, WsDialogSection, WsDialogForm } from '@mfl/common-components';

import starsIcon from '../../../assets/stars.svg';
import { BannerGalleryDialogInput } from './banner-gallery-dialog';

const { dialog } = defineProps<{
  dialog: IDialog<BannerGalleryDialogInput, string>;
}>();

const selectedCategory = ref<string>(dialog.input.banners[0].name || '');

const bannersByCategory = computed(() => {
  return dialog.input.banners.reduce(
    (categorizedBanners, category) => {
      const categoryName = category.name || '';
      const categoryImages = category.images || [];

      if (!categorizedBanners[categoryName]) {
        categorizedBanners[categoryName] = [];
      }

      categorizedBanners[categoryName] = [
        ...categorizedBanners[categoryName],
        ...categoryImages,
      ];

      return categorizedBanners;
    },
    {} as Record<string, string[]>
  );
});

function handleSelect(imageUrl: string) {
  dialog.close(imageUrl);
}
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="banner-gallery">
      <div class="banner-gallery__categories">
        <div
          v-for="category in dialog.input.banners"
          :key="category.name"
          :class="[
            'category-item',
            { active: selectedCategory === category.name },
          ]"
          @click="selectedCategory = category.name || ''"
        >
          <img
            :src="category.name === 'Featured' ? starsIcon : category.icon"
            class="category-item_icon"
            alt="icon"
          />
          {{ category.name }}
        </div>
      </div>

      <div class="banner-gallery__content">
        <div class="banner-grid">
          <div
            v-for="banner in bannersByCategory[selectedCategory]"
            :key="banner"
            class="banner-item"
            @click="handleSelect(banner)"
          >
            <img :src="banner" alt="Banner" />
          </div>
        </div>
      </div>
    </WsDialogSection>
  </WsDialogForm>
</template>

<style lang="scss" scoped>
.banner-gallery {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  padding: 0 14px;
  border-radius: 0px 0px 8px 8px;
  background: #ffffff;

  &__categories {
    display: flex;
    padding: 14px 14px 14px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 0px 0px 0px 8px;
    border-right: 1px solid var(--Gray-100, #edeeef);

    .category-item {
      display: flex;
      width: 200px;
      height: 36px;
      padding: 0px 14px;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      &_icon {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: #f5f5f5;
        border-radius: 50px;
      }

      &.active {
        border-radius: 50px;
        background-color: #e1ebff;
      }
    }
  }

  &__content {
    flex: 1;
    padding: 16px 0;
    overflow-y: auto;
    height: 460px;
    width: 682px;
  }
}

.banner-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 14px;

  .banner-item {
    cursor: pointer;
    display: flex;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 9.412px;
    align-self: stretch;
    border-radius: 7.529px;
    border: 1px solid #edeeef;

    &:hover {
      border-color: #1e90fa;
    }

    img {
      width: 300px;
      height: auto;
      display: block;
    }
  }
}
</style>
